@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*,
body {
  font-family: "Outfit" !important;
}


h2 {
  font-weight: 600;
}


/* Dashboard Layout.css */

.layout-container {
  display: flex;
  height: 100vh;
  /* background-color: #f4f4f4; */
  background-color: #F3F6F9;
  /* background: linear-gradient(90deg, rgba(249,249,255,1) 0%, rgba(233,233,255,1) 35%, rgba(233,242,255,1) 56%, rgba(229,251,255,1) 100%); */
  flex-direction: column;
}

@media (min-width: 768px) {
  .layout-container {
    flex-direction: row;
    overflow: hidden;
  }
}

.sidebar-container {
  width: 100%;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .sidebar-container {
    width: 13rem;
  }
}

.content-container {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

@media (min-width: 768px) {
  .content-container {
    padding: 0rem 1rem 2rem;
  }
}


.sidebar-container {
  overflow-y: auto;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.sidebar-container::-webkit-scrollbar {
  display: none !important;
}







.footer-section {
  background-color: #F4F5F6;
  color: black;
}

.footer-div-title {
  width: 461px;
  height: 36px;
  top: 70px;
  left: 100px;
  color: black;
  font-size: 30px;
  margin-left: -4rem;
}

.footer-txt {
  font-size: 14px;
  font-weight: 400;
}











.smart-philosophy {
  margin: 0 auto 5%;
  /* box-shadow: 0px 4px 10px 0px #DADADA; */
  width: 80%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .05);
  border-radius: 15px !important;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tab-button {
  padding: 1.3rem 1rem;
  border: none;
  color: #000;
  background-color: white;
  font-weight: 500;
  font-size: 17px;
  cursor: pointer;
  flex: 1;
}

.tab-button.active {
  background-color: #40c944;
  color: #fff;
  border-radius: 5px;
}

.tab-content {
  padding: 2rem;
  background-color: #F4F5F6;
  text-align: center;
  border-radius: 0 0 15px 15px;
}

.icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .smart-philosophy {
    width: 93%;
    margin: 5% auto 10%;
  }


  .tab-button {
    padding: 0.5rem;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .tabs {
    flex-direction: column;
  }

  .tab-button {
    flex: none;
    width: 100%;
  }
}








.contact-container {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  max-width: 1200px;
  margin: auto;
}

.form-section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-row {
  display: flex;
  gap: 1rem;
}

.input-field,
.textarea-field {
  width: 100%;
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textarea-field {
  height: 100px;
}

.checkbox-section {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.checkbox-section label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.submit-button {
  padding: 1rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.info-section {
  flex: 1;
}

.info-section h3 {
  margin-top: 1.5rem;
}

.info-section a {
  display: block;
  color: #000;
  margin: 0.5rem 0;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 1rem;
  }

  .input-row {
    flex-direction: column;
  }
}






.email-compose {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #fff;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.from,
.to {
  font-size: 16px;
  font-weight: 500;
}

.recipient {
  display: flex;
  align-items: center;
  margin-top: 5px;
  gap: 8px;
}

.recipient-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.subject-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.attachments {
  margin-top: 15px;
  font-size: 16px;
}

.attachment-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.attachment-name {
  font-size: 14px;
}

.delete-attachment {
  border: none;
  background: none;
  font-size: 16px;
  cursor: pointer;
}

.actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;
}

.broadcast,
.discard,
.send {
  padding: 8px 16px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.broadcast {
  background-color: #f0f0f0;
}

.discard {
  background-color: #f5f5f5;
}

.send {
  background-color: #ff5722;
  color: white;
}

@media (max-width: 768px) {
  .email-compose {
    padding: 15px;
  }

  .actions {
    flex-direction: column;
    gap: 5px;
  }
}


.attachment-names {
  display: flex;
  gap: 10px;
}

.attachment-names div {
  background-color: #eeeeee;
  border-radius: 50px;
  font-size: 13px;
  padding: 1px 10px;
}



div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm) {
  background-color: #40c944 !important;
}






.pricing-switcher {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 20px;
  overflow: hidden;
  width: 250px;
  justify-content: space-between;
  margin: 0 auto;
}

.option {
  flex: 1;
  padding: 10px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  background-color: #f5f5f5;
  color: #333;
  border: none;
  outline: none;
  transition: background-color 0.3s ease;
}

.option.active {
  background-color: #fff;
  color: #333;
  font-weight: bold;
}

.option .discount {
  background-color: #d4f5d4;
  color: #28a745;
  font-size: 14px;
  border-radius: 10px;
  padding: 2px 6px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .pricing-switcher {
    width: 100%;
  }

  .option {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .option {
    font-size: 12px;
  }
}


.profile-info h2 {
  margin: 10px 0 5px 0;
  font-size: 20px;
}

.profile-info p {
  margin: 0;
  color: #666;
}

/* Profile Card  */
.profile-card {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  border: 1px solid #E0E0E0;
}

.profile-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.icon-container {
  position: relative;
}

.user-icon {
  color: gainsboro;
  height: 100px;
  width: 100px;
  transition: border 0.3s ease;
}

.camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  height: 30px;
  width: 30px;
  display: none;
  cursor: pointer;
}

.icon-container:hover .camera-icon {
  display: block;
}

.icon-container:hover .user-icon {
  border: 2px double black;
  border-radius: 50%;
}